import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import CookieConsent from "react-cookie-consent";

const CookieBar = (props) => {

  // console.log('I"m a CookieBar... nom nom!!!!')
  const cookiesData = useStaticQuery(graphql`
    query CookiesQuery {
      allPrismicLayout {
        edges {
          node {
            lang
            data {
              cookies_title {
                text
              }
              message {
                html
              }
              accept_button_copy
              decline_button_copy
              bottom_message {
                html
              }
            }
          }
        }
      }
    }
  `).allPrismicLayout.edges.filter(edge => edge.node.lang === props.locale)[0]

  // console.log('CookieBar props: ', cookiesData)
  const cookieTitle = cookiesData.node.data.cookies_title.text
  const message = cookiesData.node.data.message.html
  const bottom_message = cookiesData.node.data.bottom_message.html
  const buttonCopy = cookiesData.node.data.accept_button_copy
  const declineCopy = cookiesData.node.data.decline_button_copy

  function setCookies(){
    if (typeof window !== 'undefined') {
      // console.log('set cookies!')
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({'event': 'set_cookies'})
      window.dataLayer.push({'DiscoverRakutenCookies': 'true'})
    }
  }

  return (
    <CookieConsent
      cookieName="DiscoverRakutenCookies"
      onAccept={() => {setCookies()}}
      disableStyles={true}
      overlay={true}
      location="none"
      enableDeclineButton
      buttonText={buttonCopy}
      declineButtonText={declineCopy}
      overlayClasses={props.isTextPage?'cookiebar cookiebar--text-page':'cookiebar'}
      containerClasses="cookiebar__wrapper"
      contentClasses="cookiebar__content"
      buttonWrapperClasses="cookiebar__buttons"
      buttonClasses="cookiebar__close button button--red is-rounded is-size-5-tablet is-size-4-widescreen"
      declineButtonClasses="button button--black-outline is-rounded is-size-5-tablet is-size-4-widescreen"
    >
      <div className="cookiebar__logo">
        <svg xmlns="http://www.w3.org/2000/svg" width="108px" height="33px" viewBox="0 0 108 33">
          <path fill="#bf0001" d="m86.6 27.8-59.8 5.2-5.2-5.2h65zm-62.7-7.1c2.2 0 3.8-2.2 3.8-5s-1.6-5-3.8-5c-2.2 0-3.9 2.2-3.9 5s1.6 5 3.9 5zm3.8-13.8h4v17.6h-4v-0.8c-1.1 0.8-2.4 1.2-3.8 1.2-4.6 0-8-4.1-8-9.2s3.5-9.2 8-9.2c1.4 0 2.7 0.4 3.8 1.2v-0.8zm32 0h4v17.6h-4v-0.7c-1 0.7-2.2 1.1-3.4 1.1-4.3 0-7.2-3.5-7.2-7.7v-10.4h4v10.4c0 1.9 1.3 3.6 3.3 3.6s3.3-1.6 3.3-3.6v-10.4zm37.3 17.6h-4v-17.6h4v0.7c1-0.7 2.2-1.1 3.4-1.1 4.2 0 7.2 3.5 7.2 7.7v10.4h-4v-10.4c0-1.9-1.3-3.6-3.3-3.6-1.9 0-3.3 1.6-3.3 3.6v10.4zm-92.6-17.8v6.8h2.7c1.2 0 2.4-0.6 3.1-1.7 0.6-1.1 0.6-2.4 0-3.5-0.6-1.1-1.8-1.7-3.1-1.7h-2.7zm0 17.8h-4.2v-22h6.9c3.3 0 6.2 2.1 7.2 5.3 1 3.1-0.1 6.6-2.8 8.5l6.2 8.2h-5.2l-5.1-6.8h-3v6.8zm70.1-4.2 2.6 3.5c-1.2 0.7-2.5 1.1-3.9 1.1-3 0-5.9-2.4-5.9-6.3v-7.6h-2.1v-4.2h2.1v-4.4h4v4.4h3.4v4.2h-3.4v7.6c0 1.7 1.2 2.2 1.9 2.2 0.5 0 0.9-0.2 1.3-0.4zm-32.9-5.2 8 9.4h-5.6l-5.9-7.2v7.2h-4.1v-23.5h4.1v12l4.8-6.1h5.6l-6.9 8.3zm45.6-1.7c-0.8-4.4-6.3-4.1-7.2 0h7.2zm-3.5-7c4.6 0 8.6 4.2 7.7 10.5h-11.5c0.5 4 5.1 6.1 7.8 2l3.5 2c-2.3 3.2-5 3.9-7.1 3.9-4.2 0-8.4-3.8-8.4-9.3 0-5.2 3.3-9.3 8-9.3z"/>
        </svg>
      </div>
      <h2 className="cookiebar__title">{cookieTitle}</h2>
      <div dangerouslySetInnerHTML={{ __html: message }} />
      <div className="cookiebar__bottom" dangerouslySetInnerHTML={{ __html: bottom_message }} />
    </CookieConsent>
  )
}

export default CookieBar
