import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = (props) => {

  const { site } = useStaticQuery(query)
  // const locale = props.locale

  const {
    siteMetadata: {
      defaultTitle,
      defaultDescription,
      defaultKeywords,
      defaultImage,
      defaultUrl
      // siteLanguage,
      // ogLanguage,
      // author,
      // twitter,
      // facebook,
    },
  } = site

  const seo = {
    locale: props.locale.substring(0, 2),
    title: props.title || defaultTitle,
    description: props.description || defaultDescription,
    keywords: props.keywords || defaultKeywords,
    image: `${props.origin || defaultUrl}${props.image || defaultImage}`,
    url: `${props.origin || defaultUrl}${props.pathname || "/"}`,
    noindex: props.noindex || false,
  }

  // console.log(seo.noindex)

  return (
    <>
      <Helmet title={seo.title} defer={false}>
        <html lang={seo.locale} />
        <title>{seo.title} </title>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords}/>
        <meta name="image" content={seo.image} />

        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image}  />
        <meta property="og:url" content={seo.url} />
        {/* <meta property="og:site_name" content={ogSiteName} /> */}
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={seo.locale} />
        {/* <meta property="og:image:alt" content={desc} /> */}
        {/* {name && <meta property="og:site_name" content={name} />} */}
        {/* fb:app_id */}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />
        {/* <meta name="twitter:image:alt" content={desc} /> */}
        {/* {username && <meta name="twitter:creator" content={username} />} */}

        {seo.noindex  && <meta name="robots" content="noindex, follow" />}

      </Helmet>
    </>
  )
}

export const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultKeywords: keywords
        defaultImage: image
        defaultUrl: url
      }
    }
  }
`

export default SEO
