import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"
import CookieConsent, { Cookies } from "react-cookie-consent"

const Footer = props => {
  const footerData = useStaticQuery(graphql`
    query FooterQuery {
      allPrismicLayout {
        edges {
          node {
            lang
            data {
              links {
                url {
                  uid
                }
                url_copy {
                  text
                }
              }
              copy {
                text
              }
              manage_cookies_copy
            }
          }
        }
      }
    }
  `).allPrismicLayout.edges.filter(edge => edge.node.lang === props.locale)[0]

  const links = footerData.node.data.links
  const copyright = footerData.node.data.copy.text
  const manageCookiesCopy = footerData.node.data.manage_cookies_copy

  const resetCookies = () => {
    if (typeof window !== "undefined") {
      window.dataLayer.push({ event: undefined })
      window.dataLayer.push({ DiscoverRakutenCookies: undefined })
      Cookies.remove("DiscoverRakutenCookies")
      window.location.reload("/")
    }
  }

  return (
    <div className="footer">
      <div className="container is-fluid">
        <div className="columns is-vcentered">
          <div className="column">
            <nav className="footer__menu has-text-centered-mobile">
              {links.map(link => {
                return (
                  <TransitionLink
                    to={`/${link.url.uid}`}
                    exit={{ length: 0.5 }}
                    key={link.url.uid}
                  >
                    {link.url_copy.text}
                  </TransitionLink>
                )
              })}
              <div onClick={resetCookies}>{manageCookiesCopy}</div>
            </nav>
          </div>
          <div className="column has-text-centered-mobile has-text-right-tablet">
            <div className="footer__copyright">
              © {new Date().getFullYear()} {copyright}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
