import React, { useContext, useEffect } from "react"
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { motion } from "framer-motion"
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider"

import videoBackgroundMp4 from "../assets/video/video-background.mp4"
import videoBackgroundWebm from "../assets/video/video-background.webm"
import backgroundImage from '../assets/images/img/loading-bg.jpg';

const Background = styled.div`
  position: absolute;
  top:0;
  left: 0%;
  width: 100%;
  height: 100%;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
`

const landingAnimation = {
  hidden: {
    opacity: 0,
    x: '-100%'
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      ease: "easeOut",
      duration: .5,
    }
  },
  exit:{
    opacity: 0,
  }
}
const logoAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      ease: "easeOut",
      duration: 1,
      delay: 1
    }
  },
}
const landingTextOne = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 2,
      staggerChildren: 0.7
    },
  },
}
const landingTextTwo = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 8,
    },
  },
}

const landingFrame = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      ease: "easeOut",
      duration: 1,
    },
  }
}

const Landing = (props) => {
  // console.log('Landing locale ', props.locale)
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const landingData = useStaticQuery(graphql`
    query LandingQuery {
      allPrismicLanding {
        edges {
          node {
            lang
            data {
              logo {
                url
                alt
              }
              animation_frames {
                copy {
                  text
                }
              }
            }
          }
        }
      }
    }
  `).allPrismicLanding.edges.filter(edge => edge.node.lang === props.locale)[0]

  let isEdge = null
  let isIE = null

  if (typeof window !== 'undefined') {
    isEdge = window.navigator.userAgent.indexOf('Edge') !== -1
    isIE = window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch({ type: "UPDATE_LANDING" })
      localStorage.setItem('isLandingPlayed', true);
    }, 11000);
    return () => clearTimeout(timer);
  }, [dispatch]);

  return (

    <div className={`landing ${props.locale} ${state.isLandingPlayed ? "played" : "playing"}`} >
      <div className="landing__white"></div>
      <motion.div
        className="landing__container"
        initial="hidden"
        animate="visible"
        variants={landingAnimation}
        >

        <Background />

        {!isEdge && !isIE && <video
          autoplay playsinline
          className="landing__video"
          playsInline="playsinline"
          preload="auto"
          autoPlay="autoplay"
          muted="muted"
          loop="loop">
          <source src={videoBackgroundMp4} type="video/mp4" />
          <source src={videoBackgroundWebm} type="video/webm" />
          <track kind="captions" />
          </video>
        }

        <div className="landing__content has-text-centered">
          <motion.img
            className="landing__logo"
            src={landingData.node.data.logo.url}
            alt={landingData.node.data.logo.alt}
            initial="hidden"
            animate="visible"
            variants={logoAnimation}/>
          <>
            <motion.div className="landing__text landing__text-2"
              initial="hidden"
              animate="visible"
              variants={landingTextTwo}>
              {landingData.node.data.animation_frames.map(({copy}, index, arr) => {
                if (arr.length - 1 === index) {
                  return (
                    <span
                      className={`landing__frame frame--${index} is-size-3 is-size-2-tablet is-size-1-desktop`}
                      key={index}>
                      {copy.text}
                    </span>
                  )
                }
                return null
              })}
            </motion.div>
            <motion.div
              className="landing__text landing__text-1"
              initial="hidden"
              animate="visible"
              variants={landingTextOne}>
              {landingData.node.data.animation_frames.map(({copy}, index, arr) => {
                if (arr.length - 1 !== index) {
                  return (
                    <motion.span
                      className={`landing__frame frame--${index} is-size-3 is-size-2-tablet is-size-1-desktop`}
                      key={index}
                      custom={index}
                      variants={landingFrame}>
                      {copy.text}
                    </motion.span >
                  )
                }
                return null
              })}
            </motion.div>
          </>
        </div>
      </motion.div>
    </div>

  )
}

export default Landing
