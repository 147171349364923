import React from 'react'
import TransitionLink from "gatsby-plugin-transition-link"

const TriggerButton = (props) => {

  // console.log('TriggerButton props: ', props)
  const { uid, icon, url, copy, color } = props;

  return (
    <div className={`trigger trigger--${icon}`}>
      {uid && <span>{uid}</span>}
      <TransitionLink className="trigger__link" to={url} exit={{ length: 0.5 }}>
        {copy && <span className={`trigger__about trigger__about--${color}`}>{copy}</span>}
        <i className={color}></i>
      </TransitionLink>
    </div>
  )
}

export default TriggerButton
