import React, { useContext, useEffect }  from "react"
import { osName } from 'react-device-detect';
import { GlobalDispatchContext} from "../context/GlobalContextProvider"

import Landing from '../components/Landing'
import Header from './Header'
import Footer from './Footer'
import CookieBar from './CookieBar';

import '../sass/layout.scss'

const Layout = ({ props, type, children }) => {

  console.log('Layout props ', props)

  const dispatch = useContext(GlobalDispatchContext)
  // const state = useContext(GlobalStateContext)
  let localStorageIsLandingPlayed = null
  let operatingSystem = osName.replace(/\s+/g, '').toLowerCase();

  if (typeof window !== 'undefined') {
    localStorageIsLandingPlayed = window.localStorage.getItem('isLandingPlayed')
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch({ type: "UPDATE_LOADED_FIRST_TIME" })
    }, 3000);
    return () => clearTimeout(timer);
  }, [dispatch]);

  return (
    <div className={operatingSystem}>
      {!localStorageIsLandingPlayed && type !== "error" && <Landing locale={props.pageContext.lang} />}
      <Header {...props} locale={props.pageContext.lang} />
      <main>{children}</main>
      <Footer locale={props.pageContext.lang} />
      <CookieBar locale={props.pageContext.lang} isTextPage={!!props.data.textpage} />
    </div>
  )
}

export default Layout
